export const MAX_LOADING_TIMEOUT = 2000

export const avatarDimensions = {
    WIDTH: '150',
    HEIGHT: '150',
    MIN_HEIGHT: '50'
}

export const avatarFileSize = {
    UNITS: {
        B: 'bytes',
        KB: 'kb',
        MB: 'mb',
        GB: 'gb',
    },
    AMOUNT: '75',
}

export const validateExtension = (type) => 
    (/\/(jpe?g|png)$/i).test(type)

export const validateFileSize = (size) => {
    const sizeInKb = Math.floor(size / 1000)
    return sizeInKb <= avatarFileSize.AMOUNT && sizeInKb > 0
}

export const validateDimensions = (x, y) => {
    return x === y &&
    x <= avatarDimensions.WIDTH &&
    y >= avatarDimensions.MIN_HEIGHT
}
