import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment"
import { history } from '@/app/store'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'
import CustomCargando from '@/components/CustomCargando'
import { loadActiveUser, 
    clearUserAction, 
} from '../../../../redux/reducers/_deprecated/userReducer'
import { uploadAvatar } from '@/redux/thunks/userThunks'
import './UserDetail.scss'
import AvatarUploadModal from './AvatarUpload/AvatarUploadModal'
import { emptyStateAsset } from '@/assets/img/config'
import Spinner from '@digitalhouse-tech/react-lib-ui-explorer/lib/Spinner'
import { 
    MAX_LOADING_TIMEOUT, 
    displayComponent 
} from './UserDetailSetup'
import { EDIT_USER, FULL_COURSE_ACCESS } from '@digitalhouse-tech/ts-lib-store/lib/session-storage/permission'
import { getTenantLang } from '@/helpers/tenants/helpers'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'
import { getUserOrganizations } from '@/redux/http/services/graphService'


moment.locale(getTenantLang())
const UserDetail = ({
    match,
}) => {
    const noAvatarIcon = emptyStateAsset('default-user-avatar.svg')

    const dispatch = useDispatch()
    const lang = useSelector(({ langStore }) => langStore.userDetail)
    const { activeUser } = useSelector(({ userReducer }) => userReducer)
    const avatarRef = useRef(null)
    const [showAvatarUploadModal, setShowAvatarUploadModal] = useState(false)
    const [loadedAvatar, setLoadedAvatar] = useState(false)
    const [loadedTimeout, setLoadedTimeout] = useState(false)
    const [organizations, setOrganizations] = useState([])
    const avatarEndpoint = activeUser.avatar
    const { userId } = match.params

    useEffect(() => {
        dispatch(clearUserAction())
        userId && dispatch(loadActiveUser(userId))
    }, [dispatch, match.params])

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadedTimeout(true)
        }, MAX_LOADING_TIMEOUT);
        return () => clearTimeout(timer);
    }, []);

    const handleGoBack = () => {
        dispatch(clearUserAction())
        history.goBack()
    }

    const showCourses = () => {
        const courses = []
        const { Courses } = activeUser
        if (Courses && Courses.length > 0) {
            Courses.map( ({Enrolment, id, name}) => {
                const { active } = Enrolment
                if (active) {
                    courses.push(
                        <Panel 
                            className="__card_flex_container" 
                            key={`institute_course-${id}`}
                        >
                            <div key={id}>
                                <h3 className="__card_secondary_title">
                                    {name}
                                </h3>
                            </div>
                            <div className="_btn_group" key={name}>
                                <Button
                                    id="GoToCourseButton"
                                    visible={FULL_COURSE_ACCESS()}
                                    onClick={() => goToCourse(id)}
                                    text={lang.seeMoreButton}
                                    theme='tertiary'
                                />
                            </div>
                        </Panel>
                    )
                }
            })
        }
        else {
            courses.push(
                <div className="__search_msg_result">
                    <p>{lang.noResultsFoundMessage}</p>
                </div>)
        }

        return courses

    }

    const goToCourse = (courseId) => {
        const courseLink = `/admin/courses/${courseId}`
        history.push(courseLink)
    }

    const showRoles = () => {
        let roles = ''
        const { Roles } = activeUser
        if (Roles?.length) {
            Roles.map( ({ name }) => {
                roles += `${name}, `
            })
            roles = roles.slice(0, -2)
        } else {
            roles = lang.informationNotAvailable
        }
        return roles
    }

    const getEditDate = () => {
        const { birthdate } = activeUser
        return birthdate ? 
            moment(activeUser.birthdate).utc().format("DD-MM-YYYY") :
            null
    }

    const displayUploadAvatarModal = () => {
        dispatch(loadActiveUser(userId))
        setShowAvatarUploadModal(true)
    }
    
    const handleDismiss = () => {
        setShowAvatarUploadModal(false)
    }

    const handleAvatarUpload = async (avatar, formData) => {
        const { id } = activeUser
        await dispatch(uploadAvatar(formData, id))
        avatarRef.current.src = avatar
    }

    const handleOnloadAvatar = () => {
        setLoadedAvatar(true)
    }

    const handleOnErrorAvatar = () => {
        avatarRef.current.src = noAvatarIcon
    }

    useEffect(() => {
        getUserOrganizations(userId).then(([error, data]) => {
            if (error) {
                console.error(error)
                return;
            }
            setOrganizations(data.getOrganizationByUser)
        })
    }, [userId])

    return (
        !match.params.userId || activeUser.isLoaded || activeUser.Courses ?
            <div className="container">
                <AvatarUploadModal 
                    show={showAvatarUploadModal}
                    handleDismiss={handleDismiss}
                    handleAvatarUpload={handleAvatarUpload}
                    currentAvatar={avatarEndpoint}
                    userAvatar={activeUser.avatar}
                />
                <div className="__main_header">
                    <Row>
                        <Col lg="12">
                            <h1 className="__main_title">
                                {lang.userDetailTitle}
                                <small>
                                    {`${activeUser.name} ${activeUser.surname}`}
                                </small>
                            </h1>
                        </Col>
                    </Row>
                </div>
                <Panel className="__card __fixedHeight">
                    <Row>
                        <Col lg="3">
                            <h3 
                                className="__card_secondary_title 
                                            _card_margin_bottom"
                            >
                                {lang.userInformation}
                            </h3>
                            <div className="__information_box __userDetail">
                                <div className="__label_box">
                                    <h4 className="__label title">
                                        {lang.profilePhoto}
                                    </h4>
                                    <div className="__avatar_box">
                                        {!loadedTimeout && !loadedAvatar && activeUser.avatar && 
                                        <Spinner className="loadingSpinner" />}
                                        <img
                                            style={displayComponent(!loadedAvatar)}
                                            className="avatar" 
                                            src={noAvatarIcon} 
                                            alt="no-avatar" 
                                        />

                                        <img
                                            style={displayComponent(loadedAvatar)}
                                            ref={avatarRef}
                                            className="avatar" 
                                            src={avatarEndpoint} 
                                            alt="avatar" 
                                            onLoad={handleOnloadAvatar}
                                            onError={handleOnErrorAvatar}
                                        />

                                        <Button
                                            id='change-avatar-modal'
                                            className='changeAvatarButton'
                                            visible={EDIT_USER()}
                                            onClick={displayUploadAvatarModal}
                                            text={lang.change}
                                        />
                                    </div>
                                </div>
                                <div className="__label_box">
                                    <h4 className="__label title">
                                        {lang.nameInformation}
                                    </h4>
                                    <h4 className="__label">
                                        {activeUser.name}
                                    </h4>
                                </div>

                                <div className="__label_box">
                                    <h4 className="__label title">
                                        {lang.lastnameInformation}
                                    </h4>
                                    <h4 className="__label">
                                        {activeUser.surname}
                                    </h4>
                                </div>

                                <div className="__label_box">
                                    <h4 className="__label title">
                                        {lang.phoneInformation}
                                    </h4>
                                    <h4 className="__label">
                                        {activeUser.phone ? 
                                            activeUser.phone : 
                                            lang.informationNotAvailable}
                                    </h4>
                                </div>

                                <div className="__label_box">
                                    <h4 className="__label title">
                                        {lang.emailInformation}
                                    </h4>
                                    <h4 className="__label">
                                        {activeUser.email ? 
                                            activeUser.email : 
                                            lang.informationNotAvailable}
                                    </h4>
                                </div>

                                <div className="__label_box">
                                    <h4 className="__label title">
                                        {lang.stateInformation}
                                    </h4>
                                    <h4 className="__label">
                                        {activeUser.is_active ? 
                                            lang.activeState : 
                                            lang.inactiveState}
                                    </h4>
                                </div>

                                <div className="__label_box">
                                    <h4 className="__label title">
                                        {lang.birthdateInformation}
                                    </h4>
                                    <h4 className="__label">
                                        {activeUser.birthdate ? 
                                            getEditDate() : 
                                            lang.informationNotAvailable}
                                        {' '}
                                    </h4>
                                </div>

                                <div className="__label_box">
                                    <h4 className="__label title">
                                        {lang.countryInformation}
                                    </h4>
                                    <h4 className="__label">
                                        {activeUser.Country ? 
                                            activeUser.Country.name : 
                                            lang.informationNotAvailable} 
                                        {' '}
                                        {activeUser.State ? 
                                            `, ${activeUser.State.name}` : ''} 
                                        {' '}
                                        {activeUser.City ? 
                                            `, ${activeUser.City.name}` : ''}
                                    </h4>
                                </div>

                                <div className="__label_box">
                                    <h4 className="__label title">
                                        {lang.rolsInformation}
                                    </h4>
                                    <h4 className="__label">
                                        {showRoles()}
                                    </h4>
                                </div>

                                {!!organizations.length && (
                                    <div className="__label_box">
                                        <h4 className="__label title">
                                            {lang.organizations}
                                        </h4>
                                        <ul className="__label">
                                            {
                                                organizations.map((org) => {
                                                    return (
                                                        <li key={org.name}>{org.name}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                )}

                            </div>
                        </Col>

                        <Col lg="9">
                            <h3 className="__card_secondary_title _card_margin_bottom">
                                {lang.courses}
                            </h3>
                            {showCourses()}
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <div className="__main_footer">
                                <div className="_btn_group pull-right _margin_bottom_28">
                                    <Button 
                                        id="GoBackButton" 
                                        theme="secondary" 
                                        onClick={handleGoBack} 
                                        text={lang.goBackButton} 
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Panel>
            </div>
            : <CustomCargando />
            
    )
}

UserDetail.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.object,
    }).isRequired,
}
export default UserDetail
