import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { FormControl } from 'react-bootstrap'
import Button from '@digitalhouse-tech/react-lib-ui-explorer/lib/Button'

import Select from 'react-select'
import { CustomFormField } from '@/components/CustomFormField'
import {
    saveNewUser,
    updateOneUser,
    loadActiveUser,
    clearUserAction,
    loadRoles,
    loadUserTypes,
    changeSelectedCountry,
    changeSelectedState,
    changeSelectedCity,
    clearSelectionsAction,
    changeSelectedIdType,
} from '../../../../redux/reducers/_deprecated/userReducer'
import {
    loadCountries,
    loadStatesByCountry,
    loadCitiesByState,
    loadCityValueAction,
} from '../../../../redux/reducers/_deprecated/instituteReducer'
import { history } from '@/app/store.js'
import _ from 'lodash'
import CustomCargando from '@/components/CustomCargando'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { getTenantLang } from '@/helpers/tenants/helpers'
import InputPassword from '@digitalhouse-tech/react-lib-ui-explorer/lib/InputPassword'
import Row from '@digitalhouse-tech/react-lib-ui-explorer/lib/Row'
import Col from '@digitalhouse-tech/react-lib-ui-explorer/lib/Col'
import Panel from '@digitalhouse-tech/react-lib-ui-explorer/lib/Panel'
import './UserForm.scss'
import { getUserOrganizations } from '@/redux/http/services/graphService'
import UserFiscalId from './UserFiscalId'

moment.locale(getTenantLang())

const defaultDocumentType = { country: '', flag: '', name: '', regexp: /.+/ }

const documentTypes = [
    { ...defaultDocumentType },
    { country: '', flag: '🏳️', name: 'DNI', regexp: /.+/ },
    { country: '', flag: '🏳️', name: 'CI', regexp: /.+/ },
    { country: 'AR', flag: '🇦🇷', name: 'CUIT', regexp: /^\d{11}$/ },
    { country: 'BR', flag: '🇧🇷', name: 'CPF', regexp: /^\d{11}$/ },
    { country: 'CO', flag: '🇨🇴', name: 'CC', regexp: /^\d{6,10}$/ },
    { country: 'MX', flag: '🇲🇽', name: 'CURP', regexp: /^[a-zA-Z0-9]{10,18}$/ },
]

const FISCAL_ID_TYPES = [
    { country: 'AR', flag: '🇦🇷', name: 'CUIT', regexp: /^\d{11}$/ },
    { country: 'BR', flag: '🇧🇷', name: 'CNPJ', regexp: /^[0-9]{14}$/ },
    { country: 'CL', flag: '🇨🇱', name: 'RUT', regexp: /^[a-zA-Z0-9]{8,9}$/ }
]

const DEFAULT_ERROR = { error: '', message: ''}

const isTeacherUserType = (userTypes) => userTypes?.some((element) => element.label !== 'student')

export class UserForm extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            userId: this.props.match.params.id,
            name: { error: false, message: '' },
            documentId: {error: false, message: ''},
            surname: { error: false, message: '' },
            email: { error: false, message: '' },
            password: { error: false, message: '', value: '' },
            passwordConf: { default: '', error: false, message: '', value: '' },
            phone: { error: false, message: '' },
            active: true,
            birthdate: { default: null, error: false, message: '' },
            selectedRole: null,
            selectedUserTypes: null,
            documentType: null,
            fiscalIdNumber: '',
            fiscalIdType: '',
            fiscalIdErrors: { type: DEFAULT_ERROR, number: DEFAULT_ERROR },
            isEdit: false,
            capsLockState: false,
            organizations: [],
        }

        this.submitUser = this.submitUser.bind(this)
        this.handleActive = this.handleActive.bind(this)
        this.handleBirthDate = this.handleBirthDate.bind(this)
        this.formatSelect = this.formatSelect.bind(this)
        this.changeSelectCountry = this.changeSelectCountry.bind(this)
        this.changeSelectState = this.changeSelectState.bind(this)
        this.changeSelectCity = this.changeSelectCity.bind(this)
        this.changeSelectIdType = this.changeSelectIdType.bind(this)
        this.changeFiscalIdType = this.changeFiscalIdType.bind(this)
        this.changeFiscalIdNumber = this.changeFiscalIdNumber.bind(this)
        this.handleGoBack = this.handleGoBack.bind(this)
        this.handleRoles = this.handleRoles.bind(this)
        this.handleUserTypes = this.handleUserTypes.bind(this)
        this.getEditDate = this.getEditDate.bind(this)

        this.showCapsLockMsg = this.showCapsLockMsg.bind(this)
        this.setPassword = this.setPassword.bind(this)
    }

    componentDidMount() {
        document.onkeypress = (e) => this.showCapsLockMsg(e)
        this.props.clearUserAction()
        this.props.clearSelections()
        this.props.loadCountries()
        this.props.loadRoles()
        this.props.loadUserTypes()
        // avoid issue with selected fiscal id type
        this.fiscalIdKey = Math.random()

        if (this.props.match.params.id) {
            getUserOrganizations(this.props.match.params.id).then(([error, data]) => {
                if (error) {
                    console.error(error)
                    return;
                }
                this.setState((state, props) => ({
                    ...state,
                    organizations: data.getOrganizationByUser
                }))
            })
            this.props.loadActiveUser(this.props.match.params.id)

            this.setState((state, props) => ({
                ...state,
                isEdit: true,
                selectedRole: this.formatSelect(props.activeUser.Roles),
                selectedUserTypes: this.formatSelect(this.props.activeUser.types),
                documentType: this.props.activeUser.identification_type ?? defaultDocumentType.name,
                fiscalIdType: this.props.activeUser.fiscal_id_type,
                fiscalIdNumber: this.props.activeUser.fiscal_id_number
            }))
        } else {
            this.props.loadActiveUser('')
            this.setState((state, props) => ({
                ...state,
                documentType: defaultDocumentType.name,
            }))
        }
    }

    formatSelect(data) {
        if (data == undefined || data == null) {
            return null
        }
        if (data.length === undefined) {
            return { value: data.id, label: data.name }
        }
        return data.map((el) => {
            return {
                value: el.id,
                label: el.name,
            }
        })
    }

    handleActive(selectedOption) {
        this.setState({
            ...this.state,
            active: selectedOption.value,
        })
    }

    validateInfo(name, surname, email, password, passwordConf, documentId, documentType, userTypes, fiscalIdNumber, fiscalIdType) {
        let formOk = true
        const isEdit = this.state.isEdit
        const docType = documentTypes.find(({name}) => name === documentType)
        const documentTypePattern = docType?.regexp ?? defaultDocumentType.regexp;
        const isTeacher = isTeacherUserType(userTypes)
        let nameValue = { error: false, message: '' }
        let surnameValue = { error: false, message: '' }
        let emailValue = { error: false, message: '' }
        let idValue = { error: false, message: '' }
        let passwordValue = { error: false, message: '', value: password }
        let passwordConfValue = { error: false, message: '', value: passwordConf }

        if (name == null || name == '') {
            nameValue.error = true
            nameValue.message = this.props.lang.requiredInputMessage
            formOk = false
        }

        if (surname == null || surname == '') {
            surnameValue.error = true
            surnameValue.message = this.props.lang.requiredInputMessage
            formOk = false
        }

        const valueId = documentId?.replace(/\D/g, '');
        if (isTeacher || documentId) {
            if (valueId === null || valueId === '') {
                idValue.error = true
                idValue.message = this.props.lang.requiredInputMessage
                formOk = false
            } else {
                if (!documentType || !documentTypePattern.test(valueId)) {
                    idValue.error = true
                    idValue.message = this.props.lang.idFormatMessage
                    formOk = false
                }
            }
        }

        // fiscal id validation
        let fiscalIdErrors = { type: DEFAULT_ERROR, number: DEFAULT_ERROR }
        if (isTeacher && !fiscalIdNumber) {
            fiscalIdErrors.number = { error: true, message: this.props.lang.requiredInputMessage }
            formOk = false
        }

        if (isTeacher && !fiscalIdType) {
            fiscalIdErrors.type = { error: true, message: this.props.lang.requiredInputMessage }
            formOk = false
        }

        if (fiscalIdType) {
            const regexp = (FISCAL_ID_TYPES.find(type => type.name === fiscalIdType)).regexp
            if (regexp && !regexp.test(fiscalIdNumber)) {
                fiscalIdErrors.number = { error: true, message: this.props.lang.noMatchFiscalIdNumberMessage }
                formOk = false
            }
        } else if (fiscalIdNumber) {
            fiscalIdErrors.type = { error: true, message: this.props.lang.requiredFiscalIdTypeMessage }
            formOk = false
        }

        if (!isEdit) {
            if (email == null || email == '') {
                emailValue.error = true
                emailValue.message = this.props.lang.requiredEmailMessage
                formOk = false
            } else {
                if (email.indexOf('@') === -1 || email.indexOf('.') === -1) {
                    emailValue.error = true
                    emailValue.message = this.props.lang.emailFormatMessage
                    formOk = false
                }
            }

            if (password == null || password == '') {
                passwordValue.error = true
                passwordValue.message = this.props.lang.requiredPasswordMessage
                formOk = false
            }

            if (passwordConf != password) {
                passwordConfValue.error = true
                passwordConfValue.message = this.props.lang.noMatchPasswordMessage
                formOk = false
            }
        }

        this.setState({
            ...this.state,
            name: nameValue,
            surname: surnameValue,
            email: emailValue,
            documentId: idValue,
            password: passwordValue,
            passwordConf: passwordConfValue,
            fiscalIdErrors
        })

        return formOk
    }

    submitUser() {
        const userId = this.state.userId
        const allUsers = this.props.allUsers.users

        const name = ReactDOM.findDOMNode(this.name).value
        const surname = ReactDOM.findDOMNode(this.surname).value
        const email = ReactDOM.findDOMNode(this.email).value
        const documentId = ReactDOM.findDOMNode(this.documentId)?.value?.replace(/\D/g, '')
        const fiscalIdNumber = this.state.fiscalIdNumber
        const fiscalIdType = this.state.fiscalIdType

        // TODO: porque estaba esto asi? Habia algun motivo o la manqueamos nomas?
        // TODO: si, la manquearon y no puedo refactorizarlo en este hotfix... suerte :)

        // let password = ReactDOM.findDOMNode(this.password).value
        // let password = this.state.password.value
        // let passwordConf = ReactDOM.findDOMNode(this.passwordConf).value
        // let passwordConf = this.state.passwordConf.value
        const roles = this.state.selectedRole
        const userTypes = this.state.selectedUserTypes

        let password = null
        let passwordConf = null

        if (!this.state.isEdit) {
            // password = ReactDOM.findDOMNode(this.password).value
            // passwordConf = ReactDOM.findDOMNode(this.passwordConf).value
            password = this.state.password.value
            passwordConf = this.state.passwordConf.value
        }

        const phone = ReactDOM.findDOMNode(this.phone).value
        const active = this.state.active
        const country = this.props.selectedCountry
        const state = this.props.selectedState
        const city = this.props.selectedCity
        const documentType = this.state.documentType

        let birthdate = ''
        if (this.state.birthdate.default != '') {
            birthdate = this.state.birthdate.default
        } else {
            birthdate = null
        }

        const saveData = {
            name,
            surname,
            phone,
            active,
            birthdate,
            country,
            state,
            city,
            roles,
            userTypes,
            documentId,
            documentType,
            fiscalIdNumber,
            fiscalIdType
        }

        let formValidation = null

        if (!this.state.isEdit) {
            formValidation = this.validateInfo(name, surname, email, password, passwordConf, documentId, documentType, userTypes, fiscalIdNumber, fiscalIdType)
        } else {
            formValidation = this.validateInfo(name, surname, null, null, null, documentId, documentType, userTypes, fiscalIdNumber, fiscalIdType)
        }

        if (formValidation) {
            if (!this.state.isEdit) {
                this.props.saveNewUser({
                    ...saveData,
                    email,
                    password,
                }, allUsers)
            } else {
                this.props.updateOneUser({
                    ...saveData,
                    id: userId
                }, allUsers)
            }

            history.push('/admin/users')
        }
    }

    handleBirthDate(date) {
        let dateValue = date
        this.setState({
            ...this.state,
            birthdate: { ...this.state.birthdate, default: dateValue },
        })
    }

    getEditDate() {
        let date = null
        if (this.props.activeUser.birthdate) date = moment(this.props.activeUser.birthdate).utc()

        return date
    }

    changeSelectCountry(selectedCountry) {
        this.props.loadStatesByCountry(selectedCountry.value)
        this.props.changeSelectedCountry(selectedCountry)
    }

    changeSelectState(selectedState) {
        this.props.loadCitiesByState(selectedState)
        this.props.changeSelectedState(selectedState)
    }

    changeSelectCity(selectedCity) {
        this.props.loadCityValue(selectedCity)
        this.props.changeSelectedCity(selectedCity)
    }

    changeSelectIdType(selectedIdType) {
        this.state.documentType = selectedIdType.value
        this.props.changeSelectedIdType(selectedIdType)
    }

    changeFiscalIdType(e) {
        const fiscalIdType = e?.value ?? ''
        this.setState({ fiscalIdType })
    }

    changeFiscalIdNumber(e) {
        const fiscalIdNumber = e?.target?.value ?? ''
        this.setState({ fiscalIdNumber })
    }

    handleGoBack() {
        history.push('/admin/users')
    }

    handleRoles(newSelectedRole) {
        this.setState(
            (state) => ({
                ...state,
                selectedRole: newSelectedRole,
            }),
            () => {}
        )
    }

    handleUserTypes(newSelectedUsersTypes) {
        this.setState(
            (state) => ({
                ...state,
                selectedUserTypes: newSelectedUsersTypes,
            }),
            () => {}
        )
    }

    showCapsLockMsg(e) {
        e = e ? e : window.event
        let charCode = e.keyCode || e.which

        let isShiftOn = false
        if (e.shiftKey) {
            isShiftOn = e.shiftKey
        } else if (e.modifiers) {
            isShiftOn = !!(e.modifiers & 4)
        }

        if (charCode >= 65 && charCode <= 90 && !isShiftOn) {
            this.setState({
                ...this.state,
                capsLockState: true,
            })
        } else {
            this.setState({
                ...this.state,
                capsLockState: false,
            })
        }
    }

    setPassword(value, type) {
        this.setState((state) => ({
            ...state,
            [type]: { ...state[type], value },
        }))
    }
    render() {
        if (!this.props.match.params.id || this.props.activeUser.isLoaded) {
            return (
                <React.Fragment>
                    <div className='container'>
                        <div className='__main_header'>
                            <Row>
                                <Col lg='12'>
                                    <h1 className='__main_title'>
                                        {this.state.isEdit
                                            ? this.props.lang.editUser
                                            : this.props.lang.createNewUser}
                                    </h1>
                                </Col>
                            </Row>
                        </div>
                        <Panel className='__card __fixedHeight'>
                            <Row>
                                <Col md='6'>
                                    <h3 className='__card_secondary_title'>
                                        {this.props.lang.userInformation}
                                    </h3>
                                    <CustomFormField
                                        validationState={this.state.name.error ? 'error' : null}
                                        validationMessage={this.state.name.message}
                                        bsSize='small'
                                        controlId='name'
                                        label={this.props.lang.nameLabel}
                                        inputComponent={
                                            <FormControl
                                                key='userName'
                                                bsSize='small'
                                                defaultValue={
                                                    this.state.isEdit
                                                        ? this.props.activeUser.name
                                                        : null
                                                }
                                                ref={(name) => {
                                                    this.name = name
                                                }}
                                                type='text'
                                                autoFocus
                                            ></FormControl>
                                        }
                                    />

                                    <CustomFormField
                                        validationState={this.state.surname.error ? 'error' : null}
                                        validationMessage={this.state.surname.message}
                                        bsSize='small'
                                        controlId='surname'
                                        label={this.props.lang.lastnameLabel}
                                        inputComponent={
                                            <FormControl
                                                key='userSurname'
                                                bsSize='small'
                                                defaultValue={
                                                    this.state.isEdit
                                                        ? this.props.activeUser.surname
                                                        : null
                                                }
                                                ref={(surname) => {
                                                    this.surname = surname
                                                }}
                                                type='text'
                                            ></FormControl>
                                        }
                                    />

                                    {this.state.isEdit ? (
                                        <CustomFormField
                                            validationState={
                                                this.state.email.error ? 'error' : null
                                            }
                                            validationMessage={this.state.email.message}
                                            bsSize='small'
                                            controlId='email'
                                            label={this.props.lang.emailLabel}
                                            inputComponent={
                                                <FormControl
                                                    key='userEmail'
                                                    bsSize='small'
                                                    defaultValue={
                                                        this.state.isEdit
                                                            ? this.props.activeUser.email
                                                            : null
                                                    }
                                                    readOnly={true}
                                                    ref={(email) => {
                                                        this.email = email
                                                    }}
                                                    type='text'
                                                ></FormControl>
                                            }
                                        />
                                    ) : (
                                        <CustomFormField
                                            validationState={
                                                this.state.email.error ? 'error' : null
                                            }
                                            validationMessage={this.state.email.message}
                                            bsSize='small'
                                            controlId='email'
                                            label={this.props.lang.emailLabel}
                                            inputComponent={
                                                <FormControl
                                                    key='userEmail'
                                                    bsSize='small'
                                                    defaultValue={
                                                        this.state.isEdit
                                                            ? this.props.activeUser.email
                                                            : null
                                                    }
                                                    ref={(email) => {
                                                        this.email = email
                                                    }}
                                                    type='text'
                                                ></FormControl>
                                            }
                                        />
                                    )}

                                    {!this.state.isEdit && (
                                        <React.Fragment>
                                            <InputPassword
                                                id='input-password'
                                                label={this.props.lang.passwordLabel}
                                                value={this.state.password.value}
                                                onChange={({ target: { value } }) => {
                                                    this.setPassword(value, 'password')
                                                }}
                                                error={this.state.password.error}
                                                errorMsg={this.state.password.message}
                                            />

                                            {this.state.capsLockState && (
                                                <p className='_input_capslock_msg'>
                                                    {this.props.lang.capsLockMessage}
                                                </p>
                                            )}
                                        </React.Fragment>
                                    )}

                                    {!this.state.isEdit && (
                                        <React.Fragment>
                                            <InputPassword
                                                id='password-confirm'
                                                label={this.props.lang.confirmPasswordLabel}
                                                value={this.state.passwordConf.value}
                                                onChange={({ target: { value } }) => {
                                                    this.setPassword(value, 'passwordConf')
                                                }}
                                                error={this.state.passwordConf.error}
                                                errorMsg={this.state.passwordConf.message}
                                            />

                                            {this.state.capsLockState && (
                                                <p className='_input_capslock_msg'>
                                                    {this.props.lang.capsLockMessage}
                                                </p>
                                            )}
                                        </React.Fragment>
                                    )}

                                    <CustomFormField
                                        validationState={this.state.phone.error ? 'error' : null}
                                        validationMessage={this.state.phone.message}
                                        bsSize='small'
                                        controlId='phone'
                                        label={this.props.lang.phoneNumberLabel}
                                        inputComponent={
                                            <FormControl
                                                key='userPhone'
                                                bsSize='small'
                                                defaultValue={
                                                    this.state.isEdit
                                                        ? this.props.activeUser.phone
                                                        : null
                                                }
                                                ref={(phone) => {
                                                    this.phone = phone
                                                }}
                                                type='text'
                                            ></FormControl>
                                        }
                                    />
                                </Col>

                                <Col md='6'>
                                    <h3 className='__card_secondary_title'>
                                        {this.props.lang.aditionalInformation}
                                    </h3>
                                    <CustomFormField
                                        label={this.props.lang.userStateLabel}
                                        className='user-form__user-state'
                                        inputComponent={
                                            <Select
                                                name={this.props.lang.userState}
                                                placeholder={this.props.lang.selectState}
                                                id='active'
                                                key='active'
                                                options={[
                                                    {
                                                        value: true,
                                                        label: this.props.lang.activeState,
                                                    },
                                                    {
                                                        value: false,
                                                        label: this.props.lang.inactiveState,
                                                    },
                                                ]}
                                                onChange={this.handleActive}
                                                defaultValue={
                                                    this.state.isEdit
                                                        ? this.props.activeUser.is_active
                                                            ? {
                                                                value: true,
                                                                label: this.props.lang.activeState,
                                                            }
                                                            : {
                                                                value: false,
                                                                label: this.props.lang.inactiveState,
                                                            }
                                                        : {
                                                            value: true,
                                                            label: this.props.lang.activeState,
                                                        }
                                                }
                                            ></Select>
                                        }
                                    />

                                    <CustomFormField
                                        validationState={
                                            this.state.birthdate.error ? 'error' : null
                                        }
                                        className='user-form__birthdate'
                                        validationMessage={this.state.birthdate.message}
                                        bsSize='small'
                                        controlId='birthdate'
                                        label={this.props.lang.birthdayLabel}
                                        inputComponent={
                                            <DatePicker
                                                className='form-control input-md __date_picker_width'
                                                dateFormat='DD/MM/YYYY'
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={30}
                                                selected={
                                                    this.state.birthdate.default
                                                        ? moment(this.state.birthdate.default).utc()
                                                        : this.getEditDate()
                                                }
                                                onChange={this.handleBirthDate}
                                                placeholderText={this.props.lang.selectDate}
                                                readOnly={this.state.base}
                                            />
                                        }
                                    />

                                    <CustomFormField
                                        className='selectInputStyle user-form__country'
                                        bsSize='small'
                                        controlId='country'
                                        label={this.props.lang.countryLabel}
                                        inputComponent={
                                            <Select
                                                name={this.props.lang.countries}
                                                placeholder={this.props.lang.selectOption}
                                                id='countries'
                                                key='countries'
                                                options={this.formatSelect(this.props.allCountries)}
                                                onChange={this.changeSelectCountry}
                                                defaultValue={this.props.selectedCountry}
                                                value={this.props.selectedCountry}
                                                isSearchable={true}
                                            ></Select>
                                        }
                                    />

                                    {(this.props.selectedCountry || this.props.selectedState) && (
                                        <CustomFormField
                                            className='selectInputStyle user-form__state'
                                            bsSize='small'
                                            controlId='state'
                                            label={this.props.lang.stateLabel}
                                            inputComponent={
                                                <Select
                                                    name={this.props.lang.states}
                                                    placeholder={this.props.lang.selectOption}
                                                    id='states'
                                                    key='states'
                                                    options={this.formatSelect(
                                                        this.props.allStates
                                                    )}
                                                    onChange={this.changeSelectState}
                                                    defaultValue={this.props.selectedState}
                                                    value={this.props.selectedState}
                                                    isSearchable={true}
                                                ></Select>
                                            }
                                        />
                                    )}

                                    {(this.props.selectedState || this.props.selectedCity) && (
                                        <CustomFormField
                                            className='selectInputStyle user-form__city'
                                            bsSize='small'
                                            controlId='city'
                                            label={this.props.lang.cityLabel}
                                            inputComponent={
                                                <Select
                                                    name={this.props.lang.cities}
                                                    placeholder={this.props.lang.selectOption}
                                                    id='cities'
                                                    key='cities'
                                                    options={this.formatSelect(
                                                        this.props.allCities
                                                    )}
                                                    onChange={this.changeSelectCity}
                                                    defaultValue={this.props.selectedCity}
                                                    value={this.props.selectedCity}
                                                    isSearchable={true}
                                                ></Select>
                                            }
                                        />
                                    )}

                                    <CustomFormField
                                        className='selectInputStyle user-form__role'
                                        bsSize='small'
                                        controlId='roles'
                                        label={this.props.lang.rolLabel}
                                        inputComponent={
                                            <Select
                                                name={this.props.lang.rol}
                                                placeholder={this.props.lang.selectRol}
                                                ref='roles'
                                                id='roles'
                                                key='roles'
                                                options={this.props.allRoles.map((role) => ({
                                                    value: role.id,
                                                    label: role.description,
                                                }))}
                                                onChange={this.handleRoles}
                                                defaultValue={
                                                    this.state.isEdit
                                                        ? this.props.activeUser.Roles?.map(
                                                            (role) => ({
                                                                value: role.id,
                                                                label: role.description,
                                                            })
                                                        )
                                                        : null
                                                }
                                                isSearchable
                                                isMulti
                                            ></Select>
                                        }
                                    />

                                    <CustomFormField
                                        className='selectInputStyle user-form__user-type'
                                        bsSize='small'
                                        controlId='types'
                                        label={this.props.lang.userTypeLabel}
                                        inputComponent={
                                            <Select
                                                name={this.props.lang.userType}
                                                placeholder={this.props.lang.selectUserType}
                                                ref='types'
                                                id='types'
                                                key='types'
                                                options={this.props.allUserTypes.map(
                                                    (userType) => ({
                                                        value: userType.id,
                                                        label: userType.name,
                                                    })
                                                )}
                                                onChange={this.handleUserTypes}
                                                defaultValue={
                                                    this.state.isEdit
                                                        ? this.props.activeUser.types?.map(
                                                            ({ id, name }) => ({
                                                            value: id,
                                                            label: name,
                                                            })
                                                        )
                                                        : null
                                                }
                                                isSearchable
                                                isMulti
                                            />
                                        }
                                    />
                                    <div className='user-form__id-container'>
                                        <CustomFormField
                                            className='user-form__id-field'
                                            bsSize='small'
                                            controlId='documentType'
                                            label={this.props.lang.idTypeLabel}
                                            inputComponent={
                                                <Select
                                                    name={this.props.lang.idType}
                                                    placeholder={this.props.lang.selectTypeOption}
                                                    id='documentType'
                                                    key='documentType'
                                                    options={documentTypes.map((doc) => (
                                                        {
                                                            value: doc.name,
                                                            label: `${doc.flag} ${doc.name}`,
                                                        }
                                                    ))}
                                                    onChange={this.changeSelectIdType}
                                                    defaultValue={documentTypes.map((doc) => {
                                                        const defoultValue = documentTypes.find((doc) => doc.name === this.props.activeUser.identification_type);
                                                        if (!defoultValue) return null;
                                                        return {
                                                            value: defoultValue.name,
                                                            label: `${defoultValue.flag} ${defoultValue.name}`,
                                                        }
                                                    })}
                                                    isSearchable
                                                ></Select>
                                            }
                                        />

                                        <CustomFormField
                                            className='user-form__id-type-field'
                                            validationState={this.state.documentId.error ? 'error' : null}
                                            validationMessage={this.state.documentId.message}
                                            bsSize='small'
                                            controlId='documentId'
                                            label={this.props.lang.idNumberLabel}
                                            inputComponent={
                                                <FormControl
                                                    key='documentId'
                                                    bsSize='small'
                                                    defaultValue={this.state.isEdit ? this.props.activeUser.identification_number : null}
                                                    ref={(documentId) => {
                                                        this.documentId = documentId
                                                    }}
                                                    type='text'
                                                    autoFocus
                                                ></FormControl>
                                            }
                                        />
                                    </div>
                                    <UserFiscalId
                                        key={`user-fiscal-id-${this.fiscalIdKey}`}
                                        isEdit={this.state.isEdit}
                                        lang={this.props.lang}
                                        types={FISCAL_ID_TYPES}
                                        activeUser={this.props.activeUser}
                                        onChangeType={this.changeFiscalIdType}
                                        onChangeNumber={this.changeFiscalIdNumber}
                                        errors={this.state.fiscalIdErrors}
                                    />
                                    {!!this.state.organizations?.length && (
                                        <CustomFormField
                                            key={this.state.organizations}
                                            label={this.props.lang.userOrganizations}
                                            className='user-form__organizations'
                                            inputComponent={
                                                <Select
                                                    options={this.state.organizations.map(({ name }) => (
                                                        {
                                                            value: name,
                                                            label: name,
                                                        }
                                                    ))}
                                                    onChange={this.handleActive}
                                                    defaultValue={this.state.organizations.map(({ name }) => (
                                                        {
                                                            value: name,
                                                            label: name,
                                                        }
                                                    ))}
                                                    isMulti
                                                    isDisabled
                                                ></Select>
                                            }
                                        />
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col lg='12'>
                                    <div className='__main_footer'>
                                        <div className='_btn_group pull-right _margin_bottom_28'>
                                            <Button
                                                id='handleGoBackButton'
                                                theme='secondary'
                                                onClick={this.handleGoBack}
                                                text={this.props.lang.goBackButton}
                                            />
                                            <Button
                                                id='submitUserButton'
                                                theme='primary'
                                                className='user-form--save'
                                                onClick={this.submitUser}
                                                text={this.props.lang.saveUserButton}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Panel>
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <CustomCargando />
                </React.Fragment>
            )
        }
    }
}

const mapDispatch = (dispatch) => ({
    saveNewUser: (saveInfo, allUsers) => {
        dispatch(saveNewUser(saveInfo, allUsers))
    },
    updateOneUser: (updatedInfo, allUsers) => {
        dispatch(updateOneUser(updatedInfo, allUsers))
    },
    clearUserAction: () => {
        dispatch(clearUserAction())
    },
    loadCountries: () => {
        dispatch(loadCountries())
    },
    loadStatesByCountry: (country_id) => {
        dispatch(loadStatesByCountry(country_id))
    },
    loadCitiesByState: (state_id) => {
        dispatch(loadCitiesByState(state_id))
    },
    loadActiveUser: (userId) => {
        dispatch(loadActiveUser(userId))
    },
    loadRoles: () => {
        dispatch(loadRoles())
    },
    loadUserTypes: () => dispatch(loadUserTypes()),
    loadCityValue: () => {
        dispatch(loadCityValueAction())
    },
    changeSelectedCountry: (selectedCountry) => {
        dispatch(changeSelectedCountry(selectedCountry))
    },
    changeSelectedState: (selectedState) => {
        dispatch(changeSelectedState(selectedState))
    },
    changeSelectedCity: (selectedCity) => {
        dispatch(changeSelectedCity(selectedCity))
    },
    changeSelectedIdType: (selectedIdType) => {
        dispatch(changeSelectedIdType(selectedIdType))
    },
    clearSelections: () => {
        dispatch(clearSelectionsAction())
    },
})

const mapStateToProps = (state, ownProps) => {
    return {
        allUsers: state.userReducer.allUsers,
        allUserTypes: state.userReducer.allUserTypes,
        activeUser: state.userReducer.activeUser,
        allCountries: state.instituteReducer.allCountries,
        allStates: state.instituteReducer.allStates,
        allCities: state.instituteReducer.allCities,
        allRoles: state.userReducer.allRoles,
        valueState: state.instituteReducer.valueState,
        valueCity: state.instituteReducer.valueCity,
        selectedCountry: state.userReducer.selectedCountry,
        selectedState: state.userReducer.selectedState,
        selectedCity: state.userReducer.selectedCity,
        selectedIdType: state.userReducer.selectedIdType,
        lang: state.langStore.createNewUser,
    }
}

export default connect(mapStateToProps, mapDispatch, null, { forwardRef: true })(UserForm)
