import _ from 'lodash'
import configureStore from '@/app/store'
import { getTenantLang, getTenantSession } from '../tenants/helpers'
import { VERSION } from '@/config/env'

export const getConfig = () => ({
    headers: {
        'Authorization': configureStore.store.getState().authReducer.user.token,
        'tenant-id': getTenantSession()._id,
        'ApiVersion': VERSION
    },
    params: {
        lang: getTenantLang()
    }
})

export const getPaymentMethodConfig = () => ({
    headers: {
        'Authorization': configureStore.store.getState().authReducer.user.token,
        'tenant-id': getTenantSession()._id,
    }
})

export const getFileConfig = () => ({
    headers: {
        'Authorization': configureStore.store.getState().authReducer.user.token,
        'tenant-id': getTenantSession()._id,
        'ApiVersion': VERSION

    },
    responseType: 'blob',
    params: {
        lang: getTenantLang()
    }
})

export const getConfigMultipart = () => ({
    headers: {
        'Authorization': configureStore.store.getState().authReducer.user.token,
        'content-type': 'multipart/form-data',
        'tenant-id': getTenantSession()._id,
        'ApiVersion': VERSION
    },
    params: {
        lang: getTenantLang()
    }
})

export const getErrorResponse = (err) => {
    let messageContainer = err.response.data.message
    let message = (Array.isArray(messageContainer)) ? messageContainer[0].message : messageContainer
    return { status: err.response.status, message: message }
}
export const getCoursesSelectOptions = (allCourses) => {
    let courseOptions = []

    if (allCourses && allCourses.rows) {
        allCourses.rows.forEach(function (course) {
            courseOptions.push({ value: course.id, label: course.name })
        }, this)
    }

    return courseOptions
}

export const isObjectEmpty = (object) => Object.keys(object || {}).length === 0

export const scrollTo = (DOMNode, to) => {
    DOMNode.scrollTo({
        top: to,
        behavior: "smooth"
    })
}
export const getItemOrderValues = (item) => (
    item.map(i => ({ id: i.id, order: i.order }))
)

export const monacoOptions = {
    selectOnLineNumbers: true,
    autoClosingBrackets: true,
    tabCompletion: "on",
    fontSize: 18,
    EditorAutoClosingStrategy: "always",
    wordWrap: "on",
    readOnly: false
}

export const isUndefined = (value) => {
    return value === undefined
}

export const isNull = (value) => {
    return value === null
}

export const jsUcfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const offset = (el) => {
    var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export const htmlentities = {
    encode: function (str) {
        // console.log('str from htmlentities',str)
        var buf = []
        for (var i = str.length - 1; i >= 0; i--) {
            buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''))
        }
        return buf.join('')
    },
    decode: function (str) {
        return str.replace(/&#(\d+);/g, function (match, dec) {
            return String.fromCharCode(dec)
        })
    }
}

export const validateSrcAttribute = (html) => {
    // Images nodes into arr
    const images = [...html.querySelectorAll('img')]

    if (!images.length) return
    // Filter all images src attribute
    const imagesSrc = images.filter(img => img.src)
    // Checks that both images and all srcs length are equal
    return imagesSrc.length === images.length
}

export const stripHtml = (html) => {
    // Create a new div element
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // validate src atribute in all images tag
    const hasSrcAttribute = validateSrcAttribute(temporalDivElement)
    // validate if iframe tag exists
    const hasIframe = temporalDivElement.querySelector('iframe')
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || hasSrcAttribute || hasIframe || "";
}
