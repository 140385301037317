import _ from 'lodash'
import fileDownload from 'js-file-download'
import api from '@/config/endpoints'
import axios from '@/app/requests'
import { getConfig, getErrorResponse, getConfigMultipart, getFileConfig } from '@/helpers/utils'
import { loadStatesByCountry, loadCitiesByState } from '../instituteReducer'
import { actions as managerActions } from '../../managerReducer'
import showNewMessage from '../globalAlertsReducer/alertsControllers'

// INITIAL STATE
const initialState = {
    allUsers: {
        isLoaded: false,
        users: [],
    },
    activeUser: {
        isLoaded: false,
    },
    result: [],
    isLoading: false,
    alert: {},
    uploadState: [],
    allCourseStudents: {
        isLoaded: false,
    },
    studentsInformation: {
        isLoaded: false,
    },

    isSearchActive: false,
    showModalSearch: false,
    isLoadedForModal: false,
    allRoles: [],
    selectedCity: null,
    selectedState: null,
    selectedCountry: null,
    activeSearch: {},
    allUserTypes: [],

    paginated: {
        totalRows: 0,
        pagesQuantity: 0,
        rows: [],
        nextPage: null,
    },
    isPaginatedLoaded: false,
}

// CONSTANTS
const SUCCESSFUL_USER = 'SUCCESSFUL_USER'
const CLEAR_ALERT_USER = 'CLEAR_ALERT_USER'
const QUERY_ERROR_USER = 'QUERY_ERROR_USER'
const UPLOAD_DETAILS = 'UPLOAD_DETAILS'

const ALL_PAGINATED_USERS = 'ALL_PAGINATED_USERS'

const LOAD_ACTIVE_USER = 'LOAD_ACTIVE_USER'
const CLEAR_USER = 'CLEAR_USER'
const UPDATE_USER = 'UPDATE_USER'
const LOAD_COURSE_STUDENTS = 'LOAD_COURSE_STUDENTS'

const SHOW_MODAL_SEARCH = 'SHOW_MODAL_SEARCH'
const CLOSE_MODAL_SEARCH = 'CLOSE_MODAL_SEARCH'
const CREATE_STUDENT_COURSE = 'CREATE_STUDENT_COURSE'
const DELETE_STUDENT_COURSE = 'DELETE_STUDENT_COURSE'

const UPDATE_SELECTED_COUNTRY = 'UPDATE_SELECTED_COUNTRY'
const UPDATE_SELECTED_STATE = 'UPDATE_SELECTED_STATE'
const UPDATE_SELECTED_ID_TYPE = 'UPDATE_SELECTED_ID_TYPE'
const UPDATE_SELECTED_CITY = 'UPDATE_SELECTED_CITY'
const CLEAR_SELECTIONS = 'CLEAR_SELECTIONS'
const HTTP_REQUEST_LOADING = 'HTTP_REQUEST_LOADING'
const ALL_ROLES = 'ALL_ROLES'
const ALL_USER_TYPES = 'ALL_USER_TYPES'
const CLEAR_ACTIVE_USER_SEARCH = 'CLEAR_ACTIVE_USER_SEARCH'
const SET_ACTIVE_USER_SEARCH = 'SET_ACTIVE_USER_SEARCH'
const CLEAR_ALL_USERS = 'CLEAR_ALL_USERS'
const CHANGE_IS_LOADING = 'CHANGE_IS_LOADING'

const LOAD_PAGINATED_COURSE_STUDENTS = 'LOAD_PAGINATED_COURSE_STUDENTS'

// ACTIONS
export const successful = (text) => ({
    type: SUCCESSFUL_USER,
    text,
})

export const queryError = (text) => ({
    type: QUERY_ERROR_USER,
    text,
})

export const clearUsersAlert = () => ({
    type: CLEAR_ALERT_USER,
})

export const clearUserAction = () => ({
    type: CLEAR_USER,
})

export const uploadDetails = (data) => ({
    type: UPLOAD_DETAILS,
    data,
})

export const loadPaginatedUsersAction = (data) => ({
    type: ALL_PAGINATED_USERS,
    data,
})

export const loadActiveUserAction = (data) => ({
    type: LOAD_ACTIVE_USER,
    data,
})

export const loadStudentsByCourseAction = (data) => ({
    type: LOAD_COURSE_STUDENTS,
    data,
})

export const loadPaginatedCourseStudentsAction = (data) => ({
    type: LOAD_PAGINATED_COURSE_STUDENTS,
    data,
})

export const updateUsersAction = (data) => ({
    type: UPDATE_USER,
    data,
})

export const showModalSearchAction = () => ({
    type: SHOW_MODAL_SEARCH,
})

export const closeModalSearchAction = () => ({
    type: CLOSE_MODAL_SEARCH,
})

export const loadRolesAction = (data) => ({
    type: ALL_ROLES,
    data,
})

export const loadUserTypesAction = (data) => ({
    type: ALL_USER_TYPES,
    data,
})

export const changeSelectedCountry = (data) => ({
    type: UPDATE_SELECTED_COUNTRY,
    data,
})

export const changeSelectedState = (data) => ({
    type: UPDATE_SELECTED_STATE,
    data,
})

export const changeSelectedCity = (data) => ({
    type: UPDATE_SELECTED_CITY,
    data,
})

export const changeSelectedIdType = (data) => ({
    type: UPDATE_SELECTED_ID_TYPE,
    data,
})

export const clearSelectionsAction = () => ({
    type: CLEAR_SELECTIONS,
})

export const createStudentCourseAction = (data) => ({
    type: CREATE_STUDENT_COURSE,
    data,
})

export const deleteStudentAction = (data) => ({
    type: DELETE_STUDENT_COURSE,
    data,
})

export const httpRequestLoading = (status) => ({
    type: HTTP_REQUEST_LOADING,
    status,
})

export const clearActiveUserSearchAction = () => ({
    type: CLEAR_ACTIVE_USER_SEARCH,
})

export const setActiveUserSearchAction = (data) => ({
    type: SET_ACTIVE_USER_SEARCH,
    data,
})

export const clearAllUserAction = () => ({
    type: CLEAR_ALL_USERS,
})

export const changeLoadingAction = (status) => ({
    type: CHANGE_IS_LOADING,
    status,
})

export const loadActiveUser = (userId) => (dispatch) => {
    let config = getConfig()
    axios
        .get(api.users + '/' + userId, config)
        .then((data) => {
            dispatch(loadActiveUserAction(data))
            if (data.Country) dispatch(loadStatesByCountry(data.Country.id))
            if (data.State)
                dispatch(loadCitiesByState({ label: data.State.name, value: data.State.id }))
        })
        .catch((err) => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const loadStudentsByCourse = (courseId, name, surname, email) => async (dispatch) => {
    const config = getConfig()

    try {
        const data = await axios.get(api.filterStudents(courseId, name, surname, email), config)
        dispatch(loadStudentsByCourseAction(data))
        dispatch(changeLoadingAction(false))
    } catch (err) {
        if (err.response && err.response.status) {
            dispatch(changeLoadingAction(false))
            location.href = '/'
        } else {
            dispatch(changeLoadingAction(false))
            dispatch(getErrorResponse(err))
        }
    }
}

export const loadPaginatedUsers =
    ({ order_by }, page, name, surname, email, relatedId, type) =>
    async (dispatch) => {
        dispatch(changeLoadingAction(true))
        let config = getConfig()
        try {
            const data = await axios.get(
                api.filterUsers(page, name, surname, email, relatedId, type, order_by),
                config
            )
            dispatch(loadPaginatedUsersAction(data))
            dispatch(changeLoadingAction(false))
        } catch (err) {
            if (err.response && err.response.status) {
                dispatch(changeLoadingAction(false))
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(changeLoadingAction(false))
                dispatch(getErrorResponse(err))
            }
        }
    }

const formatRoles = (data) => {
    let formated = []
    data.map((rol) => {
        formated.push(rol.value)
    })

    return formated
}

export const saveNewUser = (data) => (dispatch, getState) => {
    let config = getConfig()
    const { active, country, state, city, roles, userTypes, documentId, documentType, fiscalIdType, fiscalIdNumber, ...rest } = data
    let body = {
        is_active: active,
        country_id: country ? country.value : null,
        state_id: state ? state.value : null,
        city_id: city ? city.value : null,
        identification_number: documentId || null,
        identification_type: documentType || null,
        fiscal_id_type: fiscalIdType || '',
        fiscal_id_number: fiscalIdNumber || '',
        roles: roles ? formatRoles(roles) : null,
        types: userTypes?.map((type) => type.value),
        avatar: null,
        ...rest,
    }
    axios
        .post(api.users, body, config)
        .then((savedUser) => {
            const message = getState().langStore.createNewUser.userCreatedSuccess
            showNewMessage({
                type: 'success',
                message: message,
                isTemporal: true,
            })
            dispatch(updateUsersAction([savedUser.user]))
        })
        .catch(() => {
            const message = getState().langStore.createNewUser.userCreatedError
            showNewMessage({
                type: 'danger',
                message: message,
                isTemporal: true,
            })
        })
}

export const updateOneUser = (data, allUsers) => (dispatch) => {
    const config = getConfig()
    const { active, country, state, city, roles, userTypes, documentId, documentType, fiscalIdType, fiscalIdNumber, ...rest } = data
    const body = {
        is_active: active,
        country_id: country != null ? country.value : null,
        state_id: state != null ? state.value : null,
        city_id: city != null ? city.value : null,
        identification_number: documentId || null,
        identification_type: documentType || null,
        fiscal_id_type: fiscalIdType || '',
        fiscal_id_number: fiscalIdNumber || '',
        roles: roles ? formatRoles(roles) : null,
        types: userTypes ? formatRoles(userTypes) : null,
        avatar: null,
        ...rest,
    }
    axios
        .patch(api.users + '/' + data.id, body, config)
        .then((updatedUser) => {
            let allUsersCopy = _.cloneDeep(allUsers)
            _.remove(allUsersCopy, { id: updatedUser.id })
            allUsersCopy.push(updatedUser)
            allUsersCopy = _.orderBy(allUsersCopy, ['created_at'], ['desc'])
            dispatch(updateUsersAction(allUsersCopy))
        })
        .catch((err) => {
            dispatch(queryError(getErrorResponse(err)))
        })
}

export const uploadUsers = (file, courseId) => (dispatch) => {
    let config = getConfigMultipart()

    const formData = new FormData()
    formData.append('file', file)

    dispatch(httpRequestLoading(true))

    if (courseId) {
        axios
            .post(api.uploadUsersWithCourse(courseId), formData, config)
            .then((data) => {
                dispatch(successful('Los usuarios se cargaron correctamente'))
                dispatch(uploadDetails(data))
                dispatch(httpRequestLoading(false))
            })
            .catch((err) => {
                dispatch(httpRequestLoading(false))
                dispatch(queryError(getErrorResponse(err)))
            })
    } else {
        axios
            .post(api.uploadUsers, formData, config)
            .then((data) => {
                dispatch(httpRequestLoading(false))
                dispatch(successful('Los usuarios se cargaron correctamente'))
            })
            .catch((err) => {
                dispatch(httpRequestLoading(false))
                dispatch(queryError(getErrorResponse(err)))
            })
    }
}

export const downloadUserCSVModel = () => (dispatch) => {
    let config = getFileConfig()

    axios
        .get(api.users + '/' + 'downloadCsvModel', config)
        .then((res) => {
            fileDownload(res, 'UserCSVModel.csv')
        })
        .catch((err) => {
            dispatch(queryError(getErrorResponse(err)))
        })
}

export const downloadPrivacyPolicy = () => (dispatch) => {
    let config = getFileConfig()

    axios
        .get(api.users + '/' + 'downloadPrivacyPolicy', config)
        .then((res) => {
            fileDownload(res, 'DH_Politica_de_Privacidad.docx')
        })
        .catch((err) => {
            dispatch(queryError(getErrorResponse(err)))
        })
}

export const loadRoles = () => (dispatch) => {
    let config = getConfig()

    axios
        .get(api.roles, config)
        .then((data) => {
            dispatch(loadRolesAction(data))
        })
        .catch((err) => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

export const loadUserTypes = () => (dispatch) => {
    let config = getConfig()

    axios
        .get(api.userTypes, config)
        .then(({ data }) => {
            dispatch(loadUserTypesAction(data))
        })
        .catch((err) => {
            if (err.response && err.response.status) {
                dispatch(queryError(getErrorResponse(err)))
            } else {
                dispatch(getErrorResponse(err))
            }
        })
}

const formatElements = (data) => {
    let formated = []
    if (data.length > 0 && !data[0].User) {
        data.map((element) => {
            let elementData = {
                id: element.id,
                name: element.name,
                surname: element.surname,
                email: element.email,
            }
            formated.push(elementData)
        })
    } else {
        data.map((element) => {
            let elementData = {
                id: element.User.id,
                name: element.User.name,
                surname: element.User.surname,
                email: element.User.email,
            }
            formated.push(elementData)
        })
    }

    return formated
}

const formatInfoForReport = (data) =>
    data.map((element) => ({
        id: element.id,
        name: element.name,
        surname: element.surname,
        email: element.email,
        legal_disclaimer: element.legal_disclaimer,
        last_login: element.last_login,
    }))

const formatCountry = (data) => {
    if (data.Country) return { value: data.Country.id, label: data.Country.name }
    return null
}

const formatState = (data) => {
    if (data.State) return { value: data.State.id, label: data.State.name }
    return null
}

const formatCity = (data) => {
    if (data.City) return { value: data.City.id, label: data.City.name }
    return null
}

export const addStudents = (datos, courseId) => (dispatch) => {
    let config = getConfig()

    axios
        .post(api.enrolments + '?course_id=' + courseId, datos, config)
        .then((students) => {
            dispatch(managerActions.addPaginationRows(students))
        })
        .catch((err) => {
            dispatch(queryError(getErrorResponse(err)))
        })
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SUCCESSFUL_USER:
            return { ...state, alert: { style: 'success', text: action.text, error: false } }
        case QUERY_ERROR_USER:
            return { ...state, alert: { style: 'danger', text: action.text, error: true } }
        case CLEAR_ALERT_USER:
            return { ...state, alert: {} }
        case UPLOAD_DETAILS:
            return { ...state, uploadState: action.data }
        case ALL_PAGINATED_USERS:
            return {
                ...state,
                allUsers: {
                    ...action.data,
                    isLoaded: true,
                },
                isLoadedForModal: true,
            }
        case UPDATE_USER:
            return {
                ...state,
                allUsers: {
                    ...state.allUsers,
                    users: action.data,
                    isLoaded: true,
                },
            }
        case LOAD_COURSE_STUDENTS:
            return {
                ...state,
                allCourseStudents: {
                    students: formatElements(action.data.rows),
                    isLoaded: true,
                },
                studentsInformation: {
                    students: formatInfoForReport(action.data.rows),
                    isLoaded: true,
                },
            }
        case LOAD_PAGINATED_COURSE_STUDENTS:
            return {
                ...state,
                paginated: action.data,
                isPaginatedLoaded: true,
            }
        case CREATE_STUDENT_COURSE:
            return {
                ...state,
                allCourseStudents: {
                    students: formatElements([...state.allCourseStudents.students, ...action.data]),
                    isLoaded: true,
                },
            }
        case DELETE_STUDENT_COURSE:
            return {
                ...state,
                allCourseStudents: {
                    students: state.allCourseStudents.students.filter(
                        (student) => student.id !== action.data
                    ),
                    isLoaded: true,
                },
            }
        case LOAD_ACTIVE_USER:
            return {
                ...state,
                activeUser: {
                    ...action.data,
                    isLoaded: true,
                },
                selectedCountry: formatCountry(action.data),
                selectedState: formatState(action.data),
                selectedCity: formatCity(action.data),
            }
        case UPDATE_SELECTED_COUNTRY:
            return {
                ...state,
                selectedCountry: action.data,
                selectedState: null,
                selectedCity: null,
            }
        case UPDATE_SELECTED_STATE:
            return {
                ...state,
                selectedState: action.data,
                selectedCity: null,
            }
        case UPDATE_SELECTED_ID_TYPE:
            return {
                ...state,
                selectedIdType: action.data,
            }
        case UPDATE_SELECTED_CITY:
            return {
                ...state,
                selectedCity: action.data,
            }
        case CLEAR_USER:
            return {
                ...state,
                activeUser: {
                    isLoaded: false,
                },
            }
        case SHOW_MODAL_SEARCH:
            return {
                ...state,
                showModalSearch: true,
                isLoadedForModal: false,
            }
        case CLOSE_MODAL_SEARCH:
            return {
                ...state,
                showModalSearch: false,
                isLoadedForModal: false,
            }

        case ALL_ROLES:
            return {
                ...state,
                allRoles: action.data,
            }
        case ALL_USER_TYPES:
            return {
                ...state,
                allUserTypes: action.data,
            }
        case CLEAR_SELECTIONS:
            return {
                ...state,
                selectedCountry: null,
                selectedState: null,
                selectedCity: null,
            }
        case HTTP_REQUEST_LOADING:
            return {
                ...state,
                isLoading: action.status,
            }
        case CLEAR_ACTIVE_USER_SEARCH:
            return {
                ...state,
                activeSearch: initialState.activeSearch,
            }
        case SET_ACTIVE_USER_SEARCH:
            return {
                ...state,
                activeSearch: action.data,
            }
        case CLEAR_ALL_USERS:
            return {
                ...state,
                allUsers: {
                    isLoaded: false,
                    users: [],
                },
            }
        case CHANGE_IS_LOADING:
            return {
                ...state,
                isLoading: action.status,
            }
        default:
            return state
    }
}
